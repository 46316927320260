import sliderInit from './slider.js';
import {getFormValues, submitCompleted} from './submitAgentForm.js';
import serializeObject from './formSerialize.js';
import {trackEvent} from './events.js';
import youTubeVideoManager from './youTubeVideoManager';

import {
  submitGenericConciergeLead,
  submitAgentBrandedConciergeLead,
} from '@uc/thrift2npme/dist/lead_forms/lead_forms_service.ucfetch';

export default function siteInit() {
  trackEvent('concierge_viewed');

  const {$} = window;
  const _window = $(window);
  const window_width = _window.width();
  const window_height = _window.height();
  const section = $('section:not(#hero-mobile)');
  const UrlPath = window.location.pathname;

  const isFormHidden = false;

  if (typeof $.fn !== 'undefined') {
    $.fn.serializeObject = serializeObject;
  }

  if (UrlPath.split('/concierge/')[1] === 'agents' ||
    UrlPath.split('/concierge/')[1] === 'agents/') {
    $('.agent-hide, .corpNav-phoneNumber').remove();
    if (window_width <= 1250) {
      $('#header').remove();
    }
  }

  if (UrlPath.split('/concierge/agents/')[1] !== '') {
    $('#header-phone, .corpNav-phoneNumber').remove();
  }

  if ($('body').hasClass('concierge-app-calculator')) {
    calculatorInit();
  } else {
    btnInit();
    scrollInit();
    formInit();
    headerInit();
    sliderInit();
    window.YT && window.YT.ready(videoInit);
    analyticsInit();
  }

  function btnInit() {
    if (window_width > 768) {
      $('[class*="btn-h-"]').mouseenter(function () {
        $(this).removeClass('h_out').addClass('h_in');
      }).mouseleave(function () {
        $(this).removeClass('h_in').addClass('h_out');
      });
    }

    // Prevents double tap for hover state on iOS
    $('main a.btn, main p > a').on('click', function (ev) {
      ev.stopPropagation();
      ev.preventDefault();

      const dest = $(this).attr('href');
      window.location.href = dest;
    });
  }

  function scrollInit() {
    const headerChapters = $('#header-chapters');
    const isMobile = window_width < 900;
    const offset_factor = isMobile ? 0.5 : 0.75;
    const scroll_offset = window_height * offset_factor;

    section.each(function () {
      const _this = $(this);
      _this.attr('data-top', Math.floor(_this.offset().top));
    });

    function site_scroll() {
      const scroll_top = _window.scrollTop() + scroll_offset;
      section.each(function () {
        const _this = $(this);
        const _this_id = _this.attr('id');
        const _this_top = _this.data('top');
        const _this_next_top = _this.next().data('top') === undefined
          ? Infinity : _this.next().data('top');
        if (scroll_top > _this_top) {
          if (!_this.hasClass('scrolled')) {
            _this.addClass('scrolled');
          }
        }
        if (scroll_top > _this_top && scroll_top < _this_next_top) {
          headerChapters.find(`[data-scroll="#${_this_id}"]`).addClass('active')
              .siblings().removeClass('active');
        }
      });
    }
    site_scroll();
    $(window).scroll(site_scroll);

    $('[data-scroll]').on('click touchend', function () {
      const id = $(this).attr('data-scroll');

      $('html, body').animate({
        scrollTop: $(id).offset().top - 120,
      }, 600);
      if (id === '#contact') {
        setTimeout(() => {
          $('#contact [name="Name"]').focus();
        }, 600);
        trackEvent('Concierge Clicked Get Started Button');
      }
    });
    $('[data-scroll]').on('keypress', function (ev) {
      const id = $(this).attr('data-scroll');
      if (id !== '#contact' && (ev.key === 'Enter' || ev.key === 'Spacebar' || ev.key === ' ')) {
        $('html, body').animate({
          scrollTop: $(id).offset().top - 120,
        }, 600);
      }
    });
  }

  function headerInit() {
    const header = $('#header');

    function headerScroll() {
      const scrollTop = parseInt(_window.scrollTop());

      if (scrollTop > window_height) {
        header.addClass('header_slide_down');
      } else {
        header.removeClass('header_slide_down');
      }
    }
    headerScroll();
    _window.scroll(headerScroll);
  }

  function formInit() {
    if (isFormHidden) {
      $('#contact').remove();
    } else {
      $('#contact').addClass('scrolled');
    }

    let startedEntering = false;
    $('#contact-form input').on('keyup', function () {

      if (!startedEntering) {
        trackEvent('Concierge Started Form Submission');
        startedEntering = true;
      }

      $(this).attr('value', $(this).val());
    });

    $('#contact-agent').change(function () {
      const _this = $(this);

      if (_this.val() === 'on' || _this.val() === 'Yes') {
        _this.val('No');
      } else {
        _this.val('Yes');
      }
    });

    const contactForm = $('#contact-form');

    contactForm.on('submit', function (ev) {
      ev.preventDefault();

      const isAgentProfile = contactForm.closest('#contact-profile').length;

      const email = contactForm.find('[name=Email]').val();
      const isCompassEmail = email.includes('@compass.com');

      contactForm.find('[name=Timestamp]').val(new Date());
      contactForm.find('[name="PID"]').val(getCookie('_pid'));

      if (isAgentProfile) {
        const formValues = getFormValues(contactForm);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(window.uc.recaptchaSiteKey,
              {action: 'form/brandedConcierge'})
              .then((recaptchaToken) => {
                formValues.captchaToken = recaptchaToken;
                return submitAgentBrandedConciergeLead(formValues)
                    .then((res) => {
                      if (res.success) {
                        submitCompleted(isCompassEmail);
                        trackEvent(
                            'Concierge Branded Form Submit',
                            {
                              name: contactForm.find('[name=Name]').val(),
                              email: contactForm.find('[name=Email]').val(),
                              zip: contactForm.find('[name=Zip]').val(),
                              phone: contactForm.find('[name=Phone]').val(),
                            },
                        );
                      } else {
                        const {errors} = res;
                        validate($('#contact-form'), errors);

                        trackEvent(
                            'Concierge Branded Form Submit (Invalid)',
                            {
                              name: $(this).find('[name=Name]').val(),
                              email: $(this).find('[name=Email]').val(),
                              zip: $(this).find('[name=Zip]').val(),
                              phone: $(this).find('[name=Phone]').val(),
                            },
                        );
                      }
                    })
                    .catch((err) => {
                      throw new Error(err);
                    });
              });
        });
      } else {
        const $this = $(this);
        const $submitButton = $this.find(':submit');
        $submitButton.prop('disabled', true);
        const formObj = $this.serializeObject();

        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(window.uc.recaptchaSiteKey,
              {action: 'form/concierge'})
              .then((recaptchaToken) => {
                formObj.captchaToken = recaptchaToken;
                const isWorkingWithAgent = contactForm.find('#contact-agent').val();
                formObj.isWorkingWithAgent = isWorkingWithAgent === 'Yes';
                return submitGenericConciergeLead(formObj)
                    .then((res) => {
                      if (res.success) {
                        submittingState(contactForm);

                        if (isCompassEmail) {
                          $('#contact-success').remove();
                          $('#contact-success-agent').addClass('active');
                        } else {
                          $('#contact-success-agent').remove();
                          $('#contact-success').addClass('active');
                        }

                        $('#contact-form').addClass('sent');

                        trackEvent(
                            'Concierge Landing Form Submit',
                            {
                              name: contactForm.find('[name=Name]').val(),
                              email: contactForm.find('[name=Email]').val(),
                              zip: contactForm.find('[name=Zip]').val(),
                              phone: contactForm.find('[name=Phone]').val(),
                              workingWithAgent: isWorkingWithAgent,
                            },
                        );
                      } else {
                        const {errors} = res;
                        validate($('#contact-form'), errors);
                        trackEvent(
                            'Concierge Landing Form Submit (Invalid)',
                            {
                              name: $(this).find('[name=Name]').val(),
                              email: $(this).find('[name=Email]').val(),
                              zip: $(this).find('[name=Zip]').val(),
                              phone: $(this).find('[name=Phone]').val(),
                              workingWithAgent: $(this).find('#contactagent').val(),
                            },
                        );
                      }
                    })
                    .catch((err) => {
                      throw new Error(err);
                    })
                    .finally(() => $submitButton.prop('disabled', false));
              });
        });
      }
    });
  }

  function submittingState($this) {
    $this.find('button[type=submit]')
        .text('Submitting...')
        .addClass('no-arrow');
  }

  function validate($form, errors) {
    const name = $form.find('[name=Name]');
    const email = $form.find('[name=Email]');
    const zip = $form.find('[name=Zip]');
    const phone = $form.find('[name=Phone]');
    const formErrorField = document.querySelector('#contact-form-error p');

    for (let i = 0; i < errors.length; i++) {
      const {fieldName, message} = errors[i];

      if (fieldName === 'name') {
        name.addClass('input_error');
        formErrorField.innerText = message;
        return false;
      } else {
        name.removeClass('input_error');
      }

      if (fieldName === 'email') {
        email.addClass('input_error');
        formErrorField.innerText = message;
        return false;
      } else {
        email.removeClass('input_error');
      }

      if (fieldName === 'zip') {
        zip.addClass('input_error');
        formErrorField.innerText = message;
        return false;
      } else {
        zip.removeClass('input_error');
      }

      if (fieldName === 'phone') {
        phone.addClass('input_error');
        formErrorField.innerText = message;
        return false;
      } else {
        phone.removeClass('input_error');
      }
    }

    return true;
  }

  function json_to_query_string(json) {
    return '?' +
        Object.keys(json).map((key) => {
          return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
  }

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    let toReturn;

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.indexOf(name + '=') === 0) {
        toReturn = cookie.substring((name + '=').length, cookie.length);
      }
    }

    return toReturn;
  }

  function videoInit() {
    $('.video-placeholder').each((_, el) => {
      youTubeVideoManager.registerVideo($(el).data('youtubeId'));
    });
    $('.video-thumb, .video-cta').on('click keypress', (e) => {
      if (e.type === 'click' ||
          e.type === 'keypress' && ['Enter', 'Spacebar', ' '].includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
        const videoId = $(e.target)
            .closest('.video-outer')
            .find('.video-placeholder')
            .data('youtubeId');
        youTubeVideoManager.playVideo(videoId);
        $('.video-outer').addClass('playing');
        trackEvent('Concierge Video Clicked Below');
      }
    });
  }

  function analyticsInit() {
    const seconds = [5, 15, 30, 60, 120, 240, 480];
    seconds.forEach((second) => {
      setTimeout(() => {
        trackEvent(`Concierge Time Elapsed ${second} Seconds`);
      }, second * 1000);
    });

    const documentHeight = $(document).height();
    let hasScrolledHalfway = false;
    _window.on('scroll', () => {
      const isHalfway = _window.scrollTop() > documentHeight / 2;

      if (!hasScrolledHalfway && isHalfway) {
        trackEvent('Concierge Scrolled Halfway');
        hasScrolledHalfway = true;
      }
    });
  }

  function getUserInputs(formObj) {
    return {
      listingZipCode: formObj.listingZipCode,
      listingPrice: getInt(formObj.listingPrice),
      budgetRequest: getInt(formObj.budgetRequest),
      amountOwed: getInt(formObj.amountOwed),
      workWeeks: getInt(formObj.workWeeks),
    };
  }

  function calculatorInit() {
    if (window_width > 768) {
      $('.icon-down').remove();
    }

    $('#calculator form').on('submit', function (ev) {
      ev.preventDefault();
      const $form = $(this);
      const isValid = calculatorValidate($(this));

      if (isValid) {
        const userInputs = getUserInputs($(this).serializeObject());

        const $formSubmit = $form.find('button[type=submit]');
        $formSubmit.addClass('loading');

        calculate(userInputs).then((res) => {
          setTimeout(() => {
            const {message, additionalRequirements, approved} = res;
            // eslint-disable-next-line max-len
            const closeLink = '<a href="#" data-js="calculator-modal-close" class="icon-close" aria-label="close" id="closeBtn"></a>';

            $('#calculator').addClass('submitted');
            $('#approval-message').text(message);
            $('.calculator-modal-inner').append(closeLink);
            $('#closeBtn').trigger('focus');

            $('[data-js=calculator-modal-close]').on('click', (ev) => {
              ev.preventDefault();

              $('#calculator').removeClass('submitted');
              $('#calculator').removeClass('requirements');
              $('#calculator').removeClass('approved');
              $('.icon-close').remove();
            });

            if (additionalRequirements) {
              $('#calculator').addClass('requirements');
            }

            if (approved) {
              $('#calculator').addClass('approved');
            }

            const sheetMacro = 'https://script.google.com/macros/s/' +
              'AKfycbytL5PzuiWGG7kWgqJikpCtxYQslI3u2qgmvAzyMeK7DdTYtaA/exec';

            const {displayName, email} = window.uc.user;
            const sheetJson = {
              Name: displayName,
              Email: email,
              'Listing Zip Code': $form.find('[name=listingZipCode]').val(),
              'Listing Price': $form.find('[name=listingPrice]').val(),
              'Budget Request': $form.find('[name=budgetRequest]').val(),
              'Amount Owed': $form.find('[name=amountOwed]').val(),
              'Work Weeks': $form.find('[name=workWeeks]').val(),
              'Result Message': message,
            };

            const execQuery = json_to_query_string(sheetJson);

            fetch(`${sheetMacro}${execQuery}`)
                .catch((err) => {
                  throw new Error(err);
                });
          }, 1500);

          setTimeout(() => {
            $formSubmit.removeClass('loading');
          }, 1800);
        });
      }
    });

    $('[data-js=calculator-collapse]').on('click', (ev) => {
      ev.preventDefault();

      $('.calculator-collapse').toggleClass('active');
    });

    $('[data-js=validate-usd]').on('input', function () {
      const val = $(this).val();
      if (!val || val === '$') {
        $(this).val('');
        return;
      }
      const int = getInt(val);

      const valUpdate = formatUsd(int);
      $(this).val(valUpdate);
    });

    $('[data-js=validate-zipcode]').on('input', function () {
      const val = $(this).val();
      const int = getInt(val);
      const valUpdate = formatZipcode(int);
      $(this).val(valUpdate);
    });
  }

  function calculatorValidate($form) {
    let isValid = true;

    $form.find('input').each(function () {
      const hasVal = $(this).val() !== '';
      $(this).toggleClass('input_error', !hasVal);

      if (!hasVal) {
        isValid = false;
      }
    });

    return isValid;
  }

  function calculate(formObj) {
    return fetch('/concierge/calculator', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formObj),
    })
        .then((res) => res.json())
        .catch((err) => {
          throw new Error(err);
        });
  }

  function getInt(str) {
    const int = parseInt(str.replace(/[^0-9.]/g, ''));
    return Number.isNaN(int) ? '' : int;
  }

  function formatUsd(int) {
    return '$' + int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // only allow 5-digit zipcode
  function formatZipcode(int) {
    return int.toString().slice(0, 5);
  }
}
