import {trackEvent} from './events.js';
import youTubeVideoManager from './youTubeVideoManager';

export default function sliderInit() {
  const slider = document.querySelector('#slider');
  const controls = document.querySelector('#controls');

  if (slider && controls) {
    slideshowCtrl(slider, controls);
  }
}

function slideshowCtrl(slider, controls) {
  const keyframeIn = 400;
  const keyframeOut = 1000;

  function slideshowRun(isBackwards) {
    const slideActive = slider.querySelector('.slide-active');
    const hasAdjacent = isBackwards
      ? slideActive.previousElementSibling
      : slideActive.nextElementSibling;

    const activeVideo = slideActive.querySelector('.video-placeholder');
    if (activeVideo) {
      youTubeVideoManager.stopVideo(activeVideo.dataset.youtubeId);
    }
    if (hasAdjacent) {
      setTimeout(() => {
        slideActive.classList.remove('slide-active');
        hasAdjacent.classList.add('slide-active');
      }, keyframeIn);

    } else {
      const slideFirst = slider.firstElementChild;
      const slideLast = slider.lastElementChild;

      setTimeout(() => {
        slideLast.classList.toggle('slide-active', isBackwards);
        slideFirst.classList.toggle('slide-active', !isBackwards);
      }, keyframeIn);
    }

    slider.classList.add('slides-movingIn');

    setHeight();
    setTimeout(() => {
      slider.classList.add('slides-movingOut');
      setHeight();
    }, keyframeIn);

    setTimeout(() => {
      slider.classList.remove('slides-movingIn', 'slides-movingOut');
      slider.removeAttribute('style');
    }, keyframeOut);
  }

  // Sets height of module explicitly for smooth transition.
  function setHeight() {
    const slideActive = slider.querySelector('.slide-active');
    const slideActiveHeight = slideActive.offsetHeight;

    slider.style.height = `${slideActiveHeight}px`;
  }

  function slideProceed(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const isBackwards = ev.target.classList.contains('slider-prev');
    slideshowRun(isBackwards);

    trackEvent('Concierge Clicked Success Story Slideshow');
  }

  window.addEventListener('load', setHeight);

  [...controls.querySelectorAll('button, a')].forEach((el) => {
    el.addEventListener('click', slideProceed);
    el.addEventListener('touchend', slideProceed);
  });
}
