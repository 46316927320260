export function trackEvent(title, additionalMetrics = {}) {
  const {uc, analytics} = window;
  const _pid = getCookie('_pid');

  analytics && analytics.track && analytics.track(
      title,
      {
        'Current Geography': uc && uc.geoId || 'Geography Not Detected',
        Source: 'Concierge',
        'User ID': uc && uc.user && uc.user.personId || 'User ID Not Detected',
        referring_agent_id: uc && uc.agentRelations && uc.agentRelations.brandingAgentOrTeam &&
          uc.agentRelations.brandingAgentOrTeam.id,
        ... additionalMetrics,
        ... _pid
          ? {pid: _pid}
          : {},
      },
  );
}

function getCookie(name) {
  const cookies = document.cookie.split(';');
  let toReturn;

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(name + '=') === 0) {
      toReturn = cookie.substring((name + '=').length, cookie.length);
    }
  }

  return toReturn;
}
