class YouTubeVideo {
  static createPlayer(id) {
    return new window.YT.Player(`yt-${id}`, {
      videoId: id,
      events: {
        onStateChange: youTubeVideoManagerInstance.createOnStateChangeCallback(id),
      },
    });
  }
  constructor(id) {
    this.id = id;
    this.player = YouTubeVideo.createPlayer(id);
  }

  stop() {
    this.player.stopVideo();
  }

  play() {
    this.player.playVideo();
  }
}

class YouTubeVideoManager {
  constructor() {
    this.videos = [];
  }

  registerVideo(id) {
    this.videos.push(new YouTubeVideo(id));
  }

  playVideo(id) {
    this._getVideo(id).play();
  }

  stopVideo(id) {
    this._getVideo(id).stop();
  }

  // private
  _getVideo(id) {
    return this.videos.find((video) => video.id === id);
  }

  createOnStateChangeCallback(id) {
    return (event) => {
      if (event.data === 1) {
        this.pauseOthers(id);
      }
    };
  }

  pauseOthers(id) {
    this.videos
        .filter((video) => video.id !== id)
        .forEach((video) => video.stop());
  }
}

const youTubeVideoManagerInstance = new YouTubeVideoManager();

export default youTubeVideoManagerInstance;
