const {$} = window;

export function getFormValues(contactForm) {
  return {
    name: contactForm.find('[name="Name"]').val(),
    agentId: contactForm.find('[name="agentId"]').val(),
    agentUrl: window.location.href,
    zip: contactForm.find('[name="Zip"]').val(),
    email: contactForm.find('[name="Email"]').val(),
    phone: contactForm.find('[name="Phone"]').val(),
  };
}

export function submitCompleted(isCompassAgent) {
  if (isCompassAgent) {
    $('#contact-success').remove();
    $('#contact-success-agent').addClass('active');
  } else {
    $('#contact-success-agent').remove();
    $('#contact-success').addClass('active');
  }

  $('#contact-form').addClass('sent');
}
