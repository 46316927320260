import siteInit from './siteInit.js';

document.addEventListener('DOMContentLoaded',
    () => {
      try {
        window.renderNav();
      } catch (err) {
        console.error('RenderNav Error:', err);
      }
      siteInit();
    });
